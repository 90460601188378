<template>
  <el-dialog title="文件详情" :fullscreen="true" :modal-append-to-body="true"
             :append-to-body="true" :close-on-click-modal="false" :visible.sync="visible"
             :before-close="handleClose">
    <el-row class="detailBox">
      <el-col :span="showList ? 18 : 24" class="infoBox p_r b_d">
        <!--        <div :class="['showListBtn', showList ? 'el-icon-arrow-right':'el-icon-arrow-left']"-->
        <!--             @click="showList = !showList"></div>-->
        <div class="modelBox text_center" v-if="dataList.length == 0">
          <img src="@/assets/img/upload.png" class="m_t2">
          <div class="m_t2">暂无资源信息哦~</div>
        </div>
        <div class="modelBox text_center" v-else v-loading="loading">
          <!--三维-->
          <div v-if="listType == 0" style="height:calc(100vh - 215px)">
            <iframe id="mainIframe" ref="mainIframe" name="mainIframe" class="iframe"
                    :src="iframeSrc" frameborder="0" width="100%" height="100%"/>
          </div>
          <!--图片-->
          <el-carousel :autoplay="false" indicator-position="none" type="card"
                       height="calc(100vh - 215px)"
                       v-else-if="listType == 1">
            <el-carousel-item>
              <el-image :src="fileUrl" style="width: 100%;height: 100%" fit="contain" :preview-src-list="[fileUrl]">
                <el-image style="width: 100%;height: 100%" slot="placeholder" fit="contain"
                          :src="require('@/assets/img/collPhoto.png')"></el-image>
                <el-image style="width: 100%;height: 100%" slot="error" fit="contain"
                          :src="require('@/assets/img/collPhoto.png')"></el-image>
              </el-image>
            </el-carousel-item>
          </el-carousel>

          <div v-else style="height:calc(100vh - 215px)">
            <iframe id="frame" :src="$pdfPreviewUrl() + 'onlinePreview?url=' + fileUrl2"
                    style="width: 100%;height: 100%;"></iframe>
          </div>

          <div class="text_right downStyle">
            <el-tooltip class="item" effect="dark" content="测量" placement="top">
              <el-button size="small" :type="threeIframeType ? '' : 'primary'"
                         v-if="listType == 0"
                         @click="rangingThree" circle>
                <i class="icon-celiangleixing iconfont"/>
              </el-button>
            </el-tooltip>
            <el-button
                size="small" icon="el-icon-download"
                circle
                @click="downloadFile()"></el-button>
          </div>
        </div>
      </el-col>

      <el-col :span="6" class="infoBox b_d fileStyle" v-show="showList">
        <el-form size="small" :model="inputForm" ref="inputForm" label-width="130px" :disabled="editInfo"
                 v-if="listType == 1">
          <el-form-item label="事由/题名：" prop="fileName"
                        :rules="[{ required: true, message: '请输入事由/题名', trigger: 'blur' }]">
            <el-input v-model.trim="inputForm.fileName" maxlength="60" placeholder="限60字"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="照片号/底片号：" prop="photoNumber">
            <el-input v-model.trim="inputForm.photoNumber" maxlength="20" placeholder="限20字"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="时间：" prop="time">
            <el-date-picker type="date" value-format="yyyy-MM-dd"
                            format="yyyy-MM-dd" v-model="inputForm.time" placeholder="请选择"
                            class="w100i"></el-date-picker>
          </el-form-item>
          <el-form-item label="年度：" prop="resourceYear">
            <el-date-picker
                value-format="yyyy"
                format="yyyy"
                v-model="inputForm.resourceYear"
                type="year" placeholder="请选择" class="w100i">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="地点：" prop="place">
            <el-input v-model.trim="inputForm.place" maxlength="20" placeholder="限20字"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="人物：" prop="resourceCharacter">
            <el-input v-model.trim="inputForm.resourceCharacter" maxlength="20"
                      placeholder="限20字" clearable></el-input>
          </el-form-item>
          <el-form-item label="背景：" prop="background">
            <el-input v-model.trim="inputForm.background" maxlength="50" placeholder="限50字"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="拍摄者：" prop="photographer">
            <el-input v-model.trim="inputForm.photographer" maxlength="20"
                      placeholder="限20字" clearable></el-input>
          </el-form-item>
          <el-form-item label="作者：" prop="author">
            <el-input v-model.trim="inputForm.author" maxlength="20"
                      placeholder="限20字" clearable></el-input>
          </el-form-item>
          <el-form-item label="相册名：" prop="albumName">
            <el-input v-model.trim="inputForm.albumName" maxlength="20" placeholder="限20字"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="主题词/关键词：" prop="keyword">
            <el-input v-model.trim="inputForm.keyword" maxlength="20" placeholder="限20字"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="密级：" prop="classificationLevel">
            <el-input v-model.trim="inputForm.classificationLevel" maxlength="10"
                      placeholder="限10字" clearable></el-input>
          </el-form-item>
          <el-form-item label="保管期限：" prop="storagePeriod">
            <el-input v-model.trim="inputForm.storagePeriod" maxlength="3"
                      placeholder="如永久或10年" clearable></el-input>
          </el-form-item>
          <el-form-item label="类型规格：" prop="typeSpecifications">
            <el-input v-model.trim="inputForm.typeSpecifications" maxlength="10"
                      placeholder="限10字" clearable></el-input>
          </el-form-item>
          <el-form-item label="备注：" prop="remarks">
            <el-input v-model.trim="inputForm.remarks" maxlength="200" placeholder="限200字"
                      clearable></el-input>
          </el-form-item>
        </el-form>
        <el-form size="small" :model="inputForm" ref="inputForm" label-width="100px" v-else>
          <el-form-item label="文件名称：" prop="fileName"
                        :rules="[{ required: true, message: '请输入文件名称', trigger: 'blur' }]">
            <el-input :disabled="this.pageType != 0" v-model.trim="inputForm.fileName" maxlength="60" placeholder="限60字"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="文件大小：" prop="fileSizeFormat">
            <el-input v-model.trim="inputForm.fileSizeFormat" disabled></el-input>
          </el-form-item>
          <el-form-item label="文件格式：" prop="fileFormat">
            <el-input v-model="inputForm.fileFormat" disabled></el-input>
          </el-form-item>
          <!--          <el-form-item label="上传文件：" :rules="[{ required: true}]">-->
          <!--            <Upload :uploadAskObj="uploadRequire" @getDataList="updateFile"></Upload>-->
          <!--          </el-form-item>-->
          <el-form-item label="文件描述：" prop="fileDesc">
            <el-input v-model.trim="inputForm.fileDesc" placeholder="限20字" maxlength="20"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="配音" prop="photographed" v-if="listType == 2">
            <el-input v-model="inputForm.photographed" placeholder="限100字" maxlength="100"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="出镜" prop="impersonator" v-if="listType == 3">
            <el-input v-model="inputForm.impersonator" placeholder="限100字" maxlength="100"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="文案" prop="copywriting" v-if="listType == 2 || listType == 3">
            <el-input v-model="inputForm.copywriting" placeholder="限1000字" maxlength="1000"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="撰稿" prop="contribution" v-if="listType == 2 || listType == 3">
            <el-input v-model="inputForm.contribution" placeholder="限100字" maxlength="100"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="拍摄人：" prop="shotUser" v-if="listType == 3">
            <el-input v-model.trim="inputForm.shotUser" placeholder="限20字" maxlength="20"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="拍摄日期：" prop="shotTime" v-if="listType == 3">
            <el-date-picker class="w100i"
                            value-format="yyyy-MM-dd"
                            v-model="inputForm.shotTime"
                            type="date"
                            format=""
                            placeholder="选择日期" clearable>
            </el-date-picker>
          </el-form-item>
          <el-form-item label="拍摄任务：" prop="shotTask" v-if="listType == 3">
            <el-input v-model.trim="inputForm.shotTask" maxlength="80" placeholder="限80字"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="拍摄地点：" prop="shotAddress" v-if="listType == 3">
            <el-input v-model.trim="inputForm.shotAddress" maxlength="20" placeholder="限20字"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="拍摄设备：" prop="shotDevice" v-if="listType == 3">
            <el-input v-model.trim="inputForm.shotDevice" maxlength="80" placeholder="限80字"
                      clearable></el-input>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <div class="text_center" v-show="editInfo" style="padding-top: 15px">
      <el-button size="small" @click="handleClose()">关闭</el-button>
      <el-button v-if="pageType != 'general'" size="small" type="primary" @click="editInfo = false">编辑</el-button>
    </div>
    <div class="text_center" v-show="!editInfo" style="padding-top: 15px">
      <el-button size="small" @click="getFileInfo()">取消</el-button>
      <el-button size="small" type="primary" v-noMoreClick @click="saveInfoData()">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {Base64} from "js-base64";

export default {
  name: "fileDetails",
  data() {
    return {
      visible: false,
      showList: true,
      pageType: '',
      selectRow: {},

      dataList: [{}],
      loading: false,
      listType: 0,//文件类型
      iframeSrc: '/static/threeJs/examples/basics_obj.html',
      fileUrl: '',
      fileUrl2: '',
      threeFile:{},
      editInfo: true, //是否编辑信息
      inputForm: {
        fileDesc: '',
        impersonator: '',
        photographed: '',
        copywriting: '',
        contribution: '',
        shotUser: '',
        shotTime: '',
        shotTask: '',
        shotAddress: '',
        shotDevice: '',
        describe: '',
        resourceWidth: '',
        resourceHeight: '',
        focalLength: '',
        shutter: '',
        iso: '',
        aperture: '',
        // 图片
        photoNumber: '',
        reason: '',
        time: '',
        resourceYear: '',
        place: '',
        resourceCharacter: '',
        background: '',
        photographer: '',
        author: '',
        albumName: '',
        keyword: '',
        classificationLevel: '',
        storagePeriod: '',
        typeSpecifications: '',
        operationField: '',
        remarks: '',
      },
      threeIframeType: true,
    }
  },

  methods: {
    init(row, pageType) {
      this.threeIframeType = true
      this.selectRow = row
      this.pageType = pageType
      this.listType = row.type
      this.visible = true
      this.getFileInfo()
    },

    //获取文件信息
    getFileInfo() {
      this.loading = true
      if (this.listType == 0) {
        this.$axios(this.api.digital.comprehensiveResourcesFileShowThreeResource, {
          compressId: this.selectRow.compressId
        }, 'get').then(data => {
          if (data && data.status) {
            this.loading = false
            this.editInfo = true
            this.dataList = [data.data]
            this.threeFile = data.data.stringMapMap['null']
            console.log(this.threeFile)
            this.$nextTick(() => {
              this.loading3DResources()
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      } else {
        this.$axios(this.api.digital.comprehensiveResourcesFileGetById + this.selectRow.fileId, {}, 'get').then(data => {
          if (data && data.status) {
            this.loading = false
            this.editInfo = true
            this.dataList = [data.data]
            this.inputForm = data.data
            this.fileUrl = data.data.url
            if (this.listType != 1) {
              //转码
              this.fileUrl2 = encodeURIComponent(Base64.encode(this.fileUrl)).replaceAll("\\+", "%20")
            }
          } else {
            this.$message.error(data.msg)
          }
        })
      }
    },

    //加载三维资源
    loading3DResources(iframeSrc) {
      var that = this
      let newarr = this.threeFile
      let iframeData
      if (newarr.obj && newarr.mtl && newarr.img) {
        this.iframeSrc = iframeSrc ? iframeSrc : '/static/threeJs/examples/basics_obj.html'
        this.inputForm = newarr.obj
        that.objFile = newarr.obj
        that.mtlFile = newarr.mtl
        that.imgFile = newarr.img
        iframeData = {
          mtlUrl: newarr.mtl.url,
          objUrl: newarr.obj.url,
          imgUrl: newarr.img,
        }
      } else if (newarr.stl) {  // stl模型
        this.iframeSrc = '/static/threeJs/examples/basics_stl.html'
        this.inputForm = newarr.stl
        iframeData = {
          stlUrl: newarr.stl.url,
        }
      } else {
        this.$message('缺失文件')
        this.resourceExists = true;
        return;
      }
      // 使用ref 获取 dom
      const mapFrame = this.$refs['mainIframe']
      // 因为iframe页面打开就已经加载 获取接口成功后刷新他
      mapFrame.contentWindow.location.reload()
      if (mapFrame.attachEvent) {
        // 兼容浏览器判断 // iframe的加载完成函数
        mapFrame.attachEvent('onload', function () {
          const iframeWin = mapFrame.contentWindow
          //传递参数
          iframeWin.postMessage(iframeData, '*')
        })
      } else {
        // iframe的加载完成函数
        mapFrame.onload = function () {
          const iframeWin = mapFrame.contentWindow
          //传递参数
          iframeWin.postMessage(iframeData, '*')
        }
      }
      return
    },

    // 测距
    rangingThree() {
      this.threeIframeType = !this.threeIframeType
      if (!this.threeIframeType) {
        this.loading3DResources('/static/threeJs/examples/test_obj.html')
      } else {
        this.loading3DResources()
      }
    },

    //文件下载
    downloadFile() {
      this.$message.info("资源文件在进行下载中，请勿重复点击")
      if (this.listType == 0) {
        this.$axios(this.api.digital.comprehensiveResourcesFileLinkDownloadThreeFile, [this.selectRow.compressId], 'post', 'blob').then((res) => {
          let blob = new Blob([res.data], {type: 'application/zip'})
          let filename = '三维文件';
          let link = document.createElement('a')
          link.download = decodeURI(filename)
          link.href = window.URL.createObjectURL(blob)
          link.click()
        })
      } else {
        const a = document.createElement('a')
        const url = this.fileUrl
        fetch(url).then(res => res.blob()).then(blob => {
          a.href = URL.createObjectURL(blob)
          a.download = this.inputForm.fileName;
          document.body.appendChild(a)
          a.click();
        })
      }
    },

    //保存基本信息
    saveInfoData() {
      this.$refs['inputForm'].validate((valid) => {
        if (valid) {
          this.$axios(this.api.digital.comprehensiveResourcesFileUpdateById, this.inputForm, 'put').then(data => {
            if (data && data.status) {
              this.$message.success('保存成功')
              this.getFileInfo()
            } else {
              this.$message.error(data.msg)
            }
          })
        }
      })
    },

    handleClose() {
      this.$emit('refreshList')
      this.visible = false;
    },
  }
}
</script>

<style scoped>
.detailBox {
  padding: 0 10px;
}

.fileStyle {
  padding: 15px;
}

.infoBox {
  height: calc(100vh - 162px);
  overflow-y: auto;
}

.downStyle {
  margin-top: 15px;
  margin-right: 15px;
}
</style>
